import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/main.scss';

const Layout = ({ children }) => {
  const [isPreloaded, setIsPreloaded] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsPreloaded(false);
    }, 1000);
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: 'Karolina Czarnota (Osuch), Optometrysta, wizytówka',
              },
              { name: 'keywords', content: 'optometrysta, warszawa, badanie oczu' },
            ]}
          >
            <html lang="pl" />
          </Helmet>
          <div className={isPreloaded ? 'main-body is-preload' : 'main-body'}>
            <div id="wrapper">{children}</div>
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
